const notesRoutes = [
    {
        path: '/notes',
        name: 'notes',
        component: () => import('@/layouts/Blank.vue'),
        children: [
            {
                path: 'notes/:group',
                name: 'NotesList',
                meta: {authRequired: true},
                component: () => import('@/views/notes/Notes.vue')
            },
            {
                path: 'edit/:id',
                name: 'NotesEdit',
                meta: {authRequired: true},
                component: () => import('@/views/notes/Editor.vue')
            },
        ]
    }
]

export default notesRoutes;
