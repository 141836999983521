const usersRoutes = [
    {
        path: '/users',
        name: 'users',
        component: () => import('@/layouts/Blank.vue'),
        children: [
            {
                path: 'login',
                name: 'Login',
                meta: {anonRequired: true},
                component: () => import('@/views/users/Login.vue')
            },
            {
                path: 'register',
                name: 'Register',
                meta: { anonRequired: true },
                component: () => import('@/views/users/Register.vue')
            },
            {
                path: 'user',
                name: 'User',
                meta: { authRequired: true },
                component: () => import('@/views/users/User.vue')
            },
        ]
    }
]

export default usersRoutes;
