import { createRouter, createWebHistory } from 'vue-router'
import homeRoutes from '@/router/home.js'
import usersRoutes from '@/router/users.js'
import notesRoutes from '@/router/notes.js'
import timemgmtRoutes from '@/router/timemgmt.js'
import { isAuth, getAndUpdateUser } from '@/lib/auth.js'

const routes = [
    {
        path: '',
        name: 'base',
        component: () => import('@/layouts/Main.vue'),
        children: [...homeRoutes, ...usersRoutes, ...notesRoutes, ...timemgmtRoutes]
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import('@/views/404.vue'),
    }
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const user = getAndUpdateUser()
    if (to.meta.authRequired && !user.authenticated) {
        next({name: 'Login'})
    }
    if (to.meta.anonRequired && user.authenticated) {
        next({name: 'Home'})
    }
    if (to.meta.requiredPermission && !user.groups && !user.user.groups.includes(to.meta.requiredPermission)){
        next({name: 'Home'})
    }
    next();
});

export default router
