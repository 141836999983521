
/* eslint-disable no-unused-vars */
import MD5 from "crypto-js/md5"
import CryptoJS from 'crypto-js'
import store from '@/store/index.js'
import { authFetch } from '@/lib/api.js'

function getUser() {
    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    if (!user || !user.token) {
        user = { authenticated: false }
    } else {
        // Check if user token expired or is about to expire and than renew it
        let now = new Date()
        let now_ts = Math.floor(now.getTime() / 1000)/* + now.getTimezoneOffset() * 60*/;
        if (user.token.exp < now_ts) {
            user.authenticated = false
            user.valid = false
        }
    }
    return user;
}

function getAndUpdateUser() {
    console.log("herer")
    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    console.log(user)
    if (!user || !user.token) {
        user = { authenticated: false }
    } else {
        // Check if user token expired or is about to expire and than renew it
        let now = new Date()
        let now_ts = Math.floor(now.getTime() / 1000)/* + now.getTimezoneOffset() * 60;*/
        console.log("auth; ", user.authenticated)
        if (user.token.exp < now_ts && user.authenticated) {
            logout()
        }
    }
    return user;
}

function register(email, username, password) {
    return new Promise((resolve, reject) => {
        password = splitPassword(password)
        authFetch('/users/register', 'POST', { email: email, username: username, password: password.server })
            .then((response) => {
                resolve()
            })
            .catch((error) => {
                console.log(error.response, error.response.status)
                if (error.response.data.detail) {
                    store.commit('addMessage', { title: 'Error', titleMuted: '', message: error.response.data.detail, color: 'var(--red)' })
                } else {
                    reject()
                }
            })
    })
}

function login(username, password) {
    return new Promise((resolve, reject) => {
        password = splitPassword(password)
        authFetch('/users/login', 'POST', { username: username, password: password.server })
            .then((response) => {
                let user = response.data
                user.pass = password.client
                user.authenticated = true
                user.valid = true
                user = JSON.stringify(user)
                //Maybe verify that user is correct
                localStorage.setItem('user', user)
                store.commit('updateUser')
                resolve()
            })
            .catch((error) => {
                console.log(error.response)
                if (error.response.data.detail) {
                    store.commit('addMessage', { title: 'Error', titleMuted: '', message: error.response.data.detail, color: 'var(--red)' })
                } else {
                    reject()
                }
            })
    })
}

function logout() {
    return new Promise((resolve, reject) => {
        authFetch('/users/logout', 'POST', {})
            .then((response) => {
                if (response.status == 200) { deleteUser(); resolve() }
            })
            .catch((error) => {
                if (error.response.status == 403) { deleteUser(); resolve() }
                reject(error)
            })
    })
}

function deleteUser() {
    localStorage.removeItem('user')
    store.commit('updateUser')
}

function splitPassword(password) {
    const pass = MD5(password).toString()

    let clientPass = MD5(pass.slice(0, 16)).toString()
    let serverPass = MD5(pass.slice(16)).toString()
    console.log(clientPass, serverPass)
    return { server: serverPass, client: clientPass }
}

function expires() {
    let user = getUser()
    let now = new Date()
    let now_ts = Math.floor(now.getTime() / 1000)
    if (user.authenticated) {
        return user.token.exp - now_ts
    }
}

function isAuth(updateUser = false) {
    let user = getUser()
    return user.authenticated
}

function hasPermission(perm) {
    let user = getUser()
    if (user.authenticated) {
        if (user.user.groups) {
            return user.user.groups.includes(perm);
        }
    }
}

let code = (function() {
    return {
        encryptMessage: function(messageToencrypt = '') {
            let secretkey = getUser().pass
            var encryptedMessage = CryptoJS.AES.encrypt(messageToencrypt, secretkey);
            return btoa(encryptedMessage.toString());
        },
        decryptMessage: function(encryptedMessage = '') {
            let secretkey = getUser().pass
            encryptedMessage = atob(encryptedMessage)
            var decryptedBytes = CryptoJS.AES.decrypt(encryptedMessage, secretkey);
            var decryptedMessage = decryptedBytes.toString(CryptoJS.enc.Utf8);

            return decryptedMessage;
        }
    }
})();

export { getUser, getAndUpdateUser, login, register, logout, splitPassword, isAuth, expires, hasPermission, code }
