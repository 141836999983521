/* eslint-disable no-unused-vars */
import axios from 'axios'

const local = 0// process.env.LOCAL // This dosent work for some fucking reason and i dont have time to debug it :<


function backendServer(){
	console.log("local", process.env)
	if (local == 1){
		return 'http://127.0.0.1:8000'
	} else {
		return 'https://website598.herokuapp.com'
	}
}
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

function authFetch(path, method, data) {
    console.log(data)
    if (method == "GET"){
        return axios({
            baseURL: backendServer(),
            withCredentials: true,
            method: method,
            url: path,
            params: data,
        });   
    } else {
        return axios({
            baseURL: backendServer(),
            withCredentials: true,
            method: method,
            url: path,
            data: data,
        });   
    } 
}

function unauthFetch(path, method, data) {
    return axios({
        baseURL: backendServer(),
        method: method,
        url: path,
        data: data,
    });
}

export { authFetch, unauthFetch }
