const timemgmtRoutes = [
    {
        path: '/timemgmt',
        name: 'timemgmt',
        component: () => import('@/layouts/Blank.vue'),
        meta: {requiredPermission: 'timemgmt', authRequired: true},
        children: [
            {
                path: 'profile',
                name: 'timemgmtProfiles',
                meta: {authRequired: true},
                component: () => import('@/views/timemgmt/Profiles.vue'),
            },
            {
                path: 'profile/:profile',
                name: 'timemgmtProfileDashboard',
                meta: {authRequired: true},
                component: () => import('@/views/timemgmt/ProfileDashboard.vue')
            },
        ]
    }
]

export default timemgmtRoutes;
