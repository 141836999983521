/* eslint-disable no-unused-vars */
import { createStore } from 'vuex'
import { getUser } from '@/lib/auth.js'

export default createStore({
    state: {
        user: getUser(),
        messages: []
    },
    mutations: {
        updateUser(state) {
            state.user = getUser()
        },
        addMessage(state, payload) {
            payload.id = Math.floor(Math.random()*100000)
            state.messages.push(payload)
        },
        removeMessage(state, payload) {
            const index = state.messages.findIndex(x => x.id == payload);
            if (index > -1) {
                state.messages.splice(index, 1);
            }
        }
    },
    actions: {
    },
    modules: {
    }
})
