const homeRoutes = [
    {
        path: '/',
        name: 'Home',
        alias: '',
        component: () => import('@/views/Home.vue')
    },
    {
        path: '/debug',
        name: 'Debug',
        component: () => import('@/views/Debug.vue')
    },
    {
        path: '/calendar',
        name: 'Calendar',
        component: () => import('@/views/calendar/Calendar.vue')
    },
    {
        path: '/geografija',
        name: 'Geografija',
        component: () => import('@/views/geografija/Geografija.vue')
    },
]
export default homeRoutes
