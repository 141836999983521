<template>
 <router-view/>
</template>

<style>
 :root{
     --blue: #0D6EFD;
     --indigo: #6610F2;
     --purple: #6F42C1;
     --pink: #D63384;
     --red: #DC3545;
     --orange: #FD7E14;
     --yellow: #FFC107;
     --green: #198754;
     --teal: #20C997;
     --cyan: #0DCAF0;
     --gray: #ADB5BD;
     --gray-light: #EEEEEE;
     --gray-dark: #232323;
     --black: #000000;
     --white: #FFFFFF;
     --primary: #CCFF90;
     --primary-light: #FFFFE2;
     --primary-dark: #99CC60;
     --secondary: #FFFF00;
     --secondary-light: #FFFF5A;
     --secondary-dark: #C7CC00;
 }
</style>

<script>
 export default {
     data(){
         return {
             image: 0,
             //TODO: THis is ugly as shit fix it
             favicons: [require("@/assets/glidergof/glidergof-0.png"),require("@/assets/glidergof/glidergof-1.png"), require("@/assets/glidergof/glidergof-2.png"),require("@/assets/glidergof/glidergof-3.png"), require("@/assets/glidergof/glidergof-4.png"),require("@/assets/glidergof/glidergof-5.png"),require("@/assets/glidergof/glidergof-6.png") ,require("@/assets/glidergof/glidergof-7.png"),require("@/assets/glidergof/glidergof-8.png"),require("@/assets/glidergof/glidergof-9.png"),require("@/assets/glidergof/glidergof-10.png"),require("@/assets/glidergof/glidergof-11.png"),require("@/assets/glidergof/glidergof-12.png"),require("@/assets/glidergof/glidergof-13.png")]
         }
     },
     methods: {
         changeIcon() {
             const favicon = document.getElementById("favicon");
             favicon.href = this.favicons[this.image]
             this.image = (this.image + 1)%14
         }
     },
     beforeMount(){
         this.iconInterval = setInterval(this.changeIcon, 1000);
     },
     unmounted(){
         clearInterval(this.iconInterval)
     }
 };
</script>
